import React, { useState } from 'react';
import '../style/PasswordProtectedPage.css';

interface PasswordProtectedPageProps {
  onLogin: (password: string) => void;
}

export default function PasswordProtectedPage({ onLogin }: PasswordProtectedPageProps) {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onLogin(password);
    setPassword('');
  };

  return (
    <div className="password-protected-page">
      <div className="password-container">
        <h1 className="page-title">הכנס ססמת מנהל בבקשה</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="הזן סיסמה"
            className="password-input"
          />
          <button type="submit" className="submit-button">כניסה</button>
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
}