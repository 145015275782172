import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaRocket, FaClock, FaBook, FaUsers, FaStar, FaChevronDown, FaGlobe } from 'react-icons/fa';
import '../style/HomePage.css';


export default function HomePage() {
  const featuresRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.target instanceof HTMLElement) {
            entry.target.classList.add('animate-fade-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    const features = document.querySelectorAll('.feature');
    features.forEach((feature) => observer.observe(feature));

    return () => observer.disconnect();
  }, []);

  const scrollToFeatures = () => {
    featuresRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="home-page">
      <section className="hero-section section">
        <div className="hero-content">
          <h1 className="title">ברוכים הבאים ל-KosherMind
          </h1>
          <p className="subtitle">
            שירות הבינה המלאכותית המתקדם ביותר המוקדש לקהילה החרדית, מותאם במיוחד לצרכים הייחודיים שלכם.
          </p>
          <Link to="/ai" className="cta-button">
            להתחיל בצ'אט
          </Link>
        </div>
        <button 
          onClick={scrollToFeatures} 
          className="scroll-button"
          aria-label="גלול למטה לתכונות"
        >
          <FaChevronDown size={30} />
        </button>
      </section>

      <section  className="features-section section">
        <h2 className="section-title">השירותים שלנו</h2>
        <div className="features-grid">
          <div className="feature">
            <FaRocket className="feature-icon" />
            <h3 className="feature-title">הכנת דרשה</h3>
            <p className="feature-description">השתמשו בבינה המלאכותית שלנו להכנת הדרשה הבאה שלכם בדיוק ובקלות, עם מקורות מדויקים ורעיונות מעמיקים.</p>
          </div>
          <div className="feature">
            <FaClock className="feature-icon" />
            <h3 className="feature-title">בדיקת זמני תפילה</h3>
            <p className="feature-description">גלה את הזמן המדויק לתפילות ואירועים דתיים אחרים, מותאם למיקומך הספציפי ולמנהגי הקהילה שלך.</p>
          </div>
          <div className="feature">
            <FaBook className="feature-icon" />
            <h3 className="feature-title">לימוד הלכה</h3>
            <p className="feature-description">גישה מהירה ומדויקת להכוונה הלכתית בכל זמן ובכל מקום, עם אפשרות לשאול שאלות מורכבות ולקבל תשובות מפורטות.</p>
          </div>
        </div>
      </section>

      <section className="testimonials-section section">
        <h2 className="section-title">מה אומרים עלינו</h2>
        <div className="testimonials-grid">
          <div className="testimonial">
            <p className="testimonial-text">"KosherMind
 שינה את הדרך שבה אני מכין את הדרשות שלי. זה כלי מדהים שחוסך לי זמן רב ומעשיר את התוכן שלי."</p>
            <p className="testimonial-author">- הרב יעקב כהן</p>
          </div>
          <div className="testimonial">
            <p className="testimonial-text">"היכולת לקבל מענה הלכתי מיידי היא פשוט מהפכנית. אני ממליץ בחום לכל אחד בקהילה להשתמש בשירות הזה."</p>
            <p className="testimonial-author">- שרה לוי, מורה</p>
          </div>
        </div>
      </section>

      <section className="stats-section section">
        <div className="stats-grid">
          <div className="stat">
            <FaUsers className="stat-icon" />
            <p className="stat-number">10,000+</p>
            <p className="stat-description">משתמשים פעילים</p>
          </div>
          <div className="stat">
            <FaStar className="stat-icon" />
            <p className="stat-number">4.9/5</p>
            <p className="stat-description">דירוג ממוצע</p>
          </div>
          <div className="stat">
            <FaBook className="stat-icon" />
            <p className="stat-number">50,000+</p>
            <p className="stat-description">שאלות הלכתיות נענו</p>
          </div>
        </div>
      </section>

      <footer className="footer">
        <p>&copy; 2024 KosherMind
          . כל הזכויות שמורות.</p>
          <h3>Developed by:</h3>
            <div className="developer-info">
            <a href="https://yehudacollins.com/" target="_blank" rel="noopener noreferrer">
              <FaGlobe /> Yehuda Collins
            </a>
          </div>
        <div className="footer-links">
          <Link to="/privacy" className="footer-link">מדיניות פרטיות</Link>
          <Link to="/terms" className="footer-link">תנאי שימוש</Link>
          <Link to="/contact" className="footer-link">צור קשר</Link>
        </div>
      </footer>
    </div>
  );
}