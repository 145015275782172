import React from 'react';
import '../style/AboutPage.css';
import {  FaGlobe } from 'react-icons/fa';


export default function AboutPage() {
  return (
    <div className="about-page-container">
      <div className="about-page">
        <h1 className="about-title">אודות KosherMind
        </h1>
        <div className="about-content">
          <p>
            KosherMind
              הוא שירות חדשני המיועד לספק פתרונות בינה מלאכותית מותאמים אישית במיוחד עבור הקהילה הדתית
            המטרה שלנו היא להציע חוויית בינה מלאכותית בטוחה, מאובטחת ויעילה המכבדת את הערכים והדרישות של המשתמשים שלנו.
          </p>
          <p>
            בין אם אתם מכינים דרשה, בודקים זמני תפילה, או מחפשים הדרכה הלכתית, KosherMind
              כאן כדי לסייע לכם עם טכנולוגיה מתקדמת תוך הקפדה על התאמה מלאה לעקרונות הדתיים.
          </p>
          
          <h2 className="section-title">המשימה שלנו</h2>
          <p>
            המשימה שלנו היא לגשר על הפער בין הטכנולוגיה המודרנית לערכים המסורתיים על ידי הצעת כלי בינה מלאכותית שהם גם עוצמתיים וגם מכבדים את הצרכים הייחודיים של הקהילה האורתודוקסית.
          </p>
          
          <h2 className="section-title">הצוות שלנו</h2>
          <p>
            KosherMind
            פותח על ידי צוות מסור של אנשי מקצוע שמתלהבים הן מטכנולוגיה והן מאורח החיים הדתי
            אנו מחויבים לשיפור מתמיד של השירותים שלנו ולהבטחה שכלי הבינה המלאכותית שלנו בטוחים, יעילים וקלים לשימוש.
          </p>
          <h3>Developed by:</h3>
            <div className="developer-info">
            <a href="https://yehudacollins.com/" target="_blank" rel="noopener noreferrer">
              <FaGlobe /> Yehuda Collins
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}