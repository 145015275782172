import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import Header from './components/Header';
import AuthPage from './components/AuthPage';
import HomePage from './pages/HomePage';
import AIPage from './pages/AIPage';
import PredefinedMessagesPage from './pages/PredefinedMessagesPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import SettingsPage from './pages/SettingsPage';
import AdminPage from './pages/AdminPage';
import PasswordProtectedPage from './components/PasswordProtectedPage';

export default function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
      if (!currentUser && location.pathname !== '/auth' && location.pathname !== '/about' && location.pathname !== '/contact') {
        navigate('/auth');
      }
    });

    return () => unsubscribe();
  }, [navigate, location]);

  if (loading) {
    return (
      <div className="loading-screen">
        <p>Loading...</p>
      </div>
    );
  }

  const ProtectedAdminRoute = ({ children }) => {
    if (!isAdmin) {
      return <Navigate to="/admin-login" replace />;
    }
    return children;
  };

  const handleAdminLogin = (password) => {
    if (password === process.env.REACT_APP_ADMIN_PASSWORD) {
      setIsAdmin(true);
      navigate('/admin');
    } else {
      alert('סיסמה שגויה. נסה שוב.');
    }
  };

  return (
    <>
      {user && <Header />}
      <Routes>
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        {user ? (
          <>
            <Route path="/" element={<HomePage />} />
            <Route path="/ai" element={<AIPage />} />
            <Route path="/messages" element={<PredefinedMessagesPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route 
              path="/admin-login" 
              element={<PasswordProtectedPage onLogin={handleAdminLogin} />} 
            />
            <Route 
              path="/admin" 
              element={
                <ProtectedAdminRoute>
                  <AdminPage />
                </ProtectedAdminRoute>
              } 
            />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/auth" replace />} />
        )}
      </Routes>
    </>
  );
}