'use client'

import React, { useState, useEffect } from 'react';
import { collection, addDoc, query, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import '../style/PredefinedMessagesPage.css';

export default function PredefinedMessagesPage() {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    // Fetch all predefined messages (visible to all users)
    const q = query(collection(db, 'predefinedMessages'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messagesArray = querySnapshot.docs.map(doc => ({
        id: doc.id,
        content: doc.data().content,
        isGlobal: true // All messages are considered global
      }));
      setMessages(messagesArray);
    });

    return () => unsubscribe();
  }, []);

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleAddMessage = async () => {
    if (newMessage.trim()) {
      await addDoc(collection(db, 'predefinedMessages'), {
        content: newMessage,
        isGlobal: true // Mark the new message as global
      });
      setNewMessage('');
    }
  };

  const handleDeleteMessage = async (id) => {
    await deleteDoc(doc(db, 'predefinedMessages', id));
  };

  return (
    <div className="predefined-messages-container">
      <div className="predefined-messages-page">
        <h1 className="page-title">ניהול הודעות מוגדרות מראש</h1>
        <p className="info-text">הודעות אלו יהיו זמינות לכל המשתמשים במערכת.</p>
        <div className="add-message">
          <input
            type="text"
            value={newMessage}
            onChange={handleInputChange}
            placeholder="הוסף הודעה חדשה מוגדרת מראש"
            className="message-input"
          />
          <button onClick={handleAddMessage} className="add-button">הוסף הודעה</button>
        </div>
        <ul className="messages-list">
          {messages.map((message) => (
            <li key={message.id} className="message-item">
              <span className="message-text">{message.content}</span>
              <button className="delete-button" onClick={() => handleDeleteMessage(message.id)}>מחק</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}