import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, setDoc, updateDoc, arrayUnion, collection, query, orderBy, getDocs, where, deleteDoc } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD367GmmsrO22j4qQGD0T-ZCb7shexJ72A",
  authDomain: "gpt-project-23d13.firebaseapp.com",
  projectId: "gpt-project-23d13",
  storageBucket: "gpt-project-23d13.appspot.com",
  messagingSenderId: "1094787629302",
  appId: "1:1094787629302:web:8bf38e0b81f40bceb86cd7",
  measurementId: "G-ST66GK5DWM"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const storage = getStorage(app);

export const fetchInstructions = async () => {
  try {
    const docRef = doc(db, "ai", "instructions");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().content;
    } else {
      console.log("No instructions found in Firestore");
      return null;
    }
  } catch (error) {
    console.error("Error fetching instructions:", error);
    throw error;
  }
};

export const saveInstructions = async (instructions) => {
  try {
    const docRef = doc(db, "ai", "instructions");
    await setDoc(docRef, { content: instructions }, { merge: true });
    console.log("Instructions saved successfully");
  } catch (error) {
    console.error("Error saving instructions:", error);
    throw error;
  }
};

export const fetchChats = async (userId) => {
  try {
    const chatsRef = collection(db, "chats");
    const q = query(chatsRef, where("userId", "==", userId), orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching chats:", error);
    throw error;
  }
};

export const createChat = async (chat) => {
  try {
    const newChatRef = doc(collection(db, "chats"));
    await setDoc(newChatRef, { ...chat, id: newChatRef.id, createdAt: new Date() });
    return newChatRef.id;
  } catch (error) {
    console.error("Error creating chat:", error);
    throw error;
  }
};

export const updateChat = async (chatId, message) => {
  try {
    if (!chatId) {
      throw new Error("Chat ID is undefined or null");
    }
    const chatRef = doc(db, "chats", chatId);
    await updateDoc(chatRef, {
      messages: arrayUnion(message)
    });
  } catch (error) {
    console.error("Error updating chat:", error);
    throw error;
  }
};

export const updateChatTitle = async (chatId, title) => {
  try {
    if (!chatId) {
      throw new Error("Chat ID is undefined or null");
    }
    const chatRef = doc(db, "chats", chatId);
    await updateDoc(chatRef, { title });
  } catch (error) {
    console.error("Error updating chat title:", error);
    throw error;
  }
};

export const deleteChat = async (chatId) => {
  try {
    if (!chatId) {
      throw new Error("Chat ID is undefined or null");
    }
    const chatRef = doc(db, "chats", chatId);
    await deleteDoc(chatRef);
    console.log("Chat deleted successfully");
  } catch (error) {
    console.error("Error deleting chat:", error);
    throw error;
  }
};