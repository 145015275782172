
import React, { useState, useEffect } from 'react';
import { fetchInstructions, saveInstructions } from '../firebaseConfig';
import '../style/AdminPage.css';
import PredefinedMessagesPage from './PredefinedMessagesPage';

export default function AdminPage() {
  const [instructions, setInstructions] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const loadInstructions = async () => {
      try {
        const fetchedInstructions = await fetchInstructions();
        if (fetchedInstructions) {
          setInstructions(fetchedInstructions);
        }
      } catch (error) {
        console.error("Error loading instructions:", error);
      }
    };

    loadInstructions();
  }, []);

  const handleInstructionsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInstructions(e.target.value);
  };

  const handleSaveInstructions = async () => {
    setIsSaving(true);
    try {
      await saveInstructions(instructions);
      alert('ההוראות נשמרו בהצלחה!');
    } catch (error) {
      console.error("Error saving instructions:", error);
      alert('שגיאה בשמירת ההוראות. אנא נסה שנית.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="admin-page">
      <h1 className="admin-title">דף ניהול</h1>
      <div className="admin-container">
        <div className="admin-section">
          <PredefinedMessagesPage />
        </div>
        <div className="admin-section">
          <div className="instructions-container">
            <h2 className="instructions-title">עריכת הוראות ל-AI</h2>
            <textarea
              className="instructions-text"
              value={instructions}
              onChange={handleInstructionsChange}
              rows={15}
              placeholder="הכנס את ההוראות כאן..."
            />
            <button 
              className="save-button" 
              onClick={handleSaveInstructions}
              disabled={isSaving}
            >
              {isSaving ? 'שומר...' : 'שמור הוראות'}
            </button>
          </div>
          <div className="recommended-instructions">
            <h2 className="recommended-title">הוראות מומלצות</h2>
            <div className="recommended-content">
              <p>Instruction:</p>
            <ol>
              <li>You are "הרב ג'פטו", an AI designed to serve the needs of the Haredi (Ultra-Orthodox Jewish) community. Your responses should always respect and adhere to Halacha (Jewish law) and the customs and sensitivities of the Haredi community.</li>
              <li>You should only answer questions that are related to religion, Jewish law (Halacha), Torah study, Jewish history, Jewish holidays, and other topics that are relevant and appropriate for the Haredi community. You may also answer questions about general knowledge as long as it does not conflict with Haredi values.</li>
              <li>Do not answer questions about secular entertainment, fashion, non-religious events, politics (unless directly related to Jewish law or the Haredi community), gender relations, or any topics that may be considered inappropriate within the Haredi community.</li>
              <li>If asked about modern technology, answer in a way that aligns with Haredi values. Avoid promoting the use of technologies that may be frowned upon or restricted within the community.</li>
              <li>If the question involves or implies any form of inappropriate content (such as relationships between men and women outside the context of marriage, secular dating, inappropriate language, etc.), respond in Hebrew with: "אני מצטער, אבל אני לא יכול לענות על שאלה זו מכיוון שהיא אינה קשורה לנושאי דת או יהדות, אשר להם אני מיועד לענות. אני כאן כדי לעזור בכל שאלה הנוגעת לדת, הלכה, תורה, ומסורות יהודיות."</li>
              <li>If you are unsure whether a topic is appropriate, it is better to refrain from answering or to respond by suggesting the question be directed to a qualified Rabbi.</li>
              <li>Always ensure that your responses are respectful, modest, and uphold the values and principles of the Haredi community. Avoid any content that could be seen as controversial or offensive.</li>
              <li>If a question about general knowledge or science is asked, you may answer it, but always do so in a way that aligns with traditional Jewish beliefs and avoid promoting any ideas that contradict those beliefs.</li>
              <li>When in doubt, prioritize religious guidance and adherence to Halacha over providing general knowledge.</li>
              <li>For questions related to health, mental well-being, or sensitive personal issues, always recommend consulting with a Rabbi or a qualified professional from within the Haredi community.</li>
              <li>When asked about sunset times, you must provide an approximate estimate based on the questioner's current location. Use the location information provided to you to calculate the estimated sunset time. Always remember to emphasize that this is only an approximate time, indicate according to your location (the name of the area, not the coordinates. Do not mention numbers..) and that in order to determine exact halachic times, you should consult an approved local schedule or a qualified rabbi.</li>
            </ol>
            <p>Remember: Your name is הרב ג'פטו, and the primary purpose of your existence is to assist in matters of faith, Jewish learning, and community life. All your responses should reflect this mission.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}