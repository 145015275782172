import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaBars, FaTimes, FaUserCircle, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { auth } from '../firebaseConfig';
import { signOut } from 'firebase/auth';
import '../style/Header.css';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
      closeMenu();
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleAdminClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate('/admin-login');
    closeMenu();
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    closeMenu();
  }, [location.pathname]);

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="header-content">
        <div className="logo">
          <Link to="/" className="logo-link" onClick={closeMenu}>
            <span className="logo-text">KosherMind</span>
          </Link>
        </div>
        <nav className={`nav ${isMenuOpen ? 'open' : ''}`}>
          <ul>
            <li><Link to="/" className={location.pathname === '/' ? 'active' : ''} onClick={closeMenu}>דף הבית</Link></li>
            <li><Link to="/ai" className={location.pathname === '/ai' ? 'active' : ''} onClick={closeMenu}>שירותי בינה מלאכותית</Link></li>
            <li><Link to="/about" className={location.pathname === '/about' ? 'active' : ''} onClick={closeMenu}>אודות</Link></li>
            <li><Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''} onClick={closeMenu}>צור קשר</Link></li>
          </ul>
        </nav>
        <div className="user-actions">
          <Link to="/settings" className="icon-button" title="הגדרות" onClick={closeMenu}>
            <FaCog />
          </Link>
          <button className="icon-button" title="ניהול" onClick={handleAdminClick}>
            <FaUserCircle />
          </button>
          <button onClick={handleLogout} className="icon-button" title="התנתק">
            <FaSignOutAlt />
          </button>
        </div>
        <div className="mobile-actions">
          <Link to="/settings" className="icon-button" title="הגדרות" onClick={closeMenu}>
            <FaCog />
          </Link>
          <button className="menu-toggle" onClick={toggleMenu} aria-label="תפריט">
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>
    </header>
  );
}