import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { deleteUser, signOut } from 'firebase/auth';
import '../style/SettingsPage.css';

export default function SettingsPage() {
  const [language, setLanguage] = useState('English');
  const [darkMode, setDarkMode] = useState(false);
  const [notifications, setNotifications] = useState(true);
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserName(user.displayName || user.email || 'User');
    }
  }, []);

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(e.target.value);
  };

  const handleDarkModeToggle = () => {
    setDarkMode(!darkMode);
    // Here you would typically apply the dark mode to the entire app
    document.body.classList.toggle('dark-mode');
  };

  const handleNotificationsToggle = () => {
    setNotifications(!notifications);
  };

  const handleDeleteAccount = async () => {
    if (window.confirm("האם אתה בטוח שברצונך למחוק את החשבון שלך? פעולה זו אינה ניתנת לביטול.")) {
      try {
        const user = auth.currentUser;
        if (user) {
          await deleteUser(user);
          navigate('/');
        }
      } catch (error) {
        console.error("Error deleting account: ", error);
        alert("נכשל במחיקת החשבון. אנא נסה שוב.");
      }
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error("Error signing out: ", error);
      alert("נכשל בהתנתקות. אנא נסה שוב.");
    }
  };

  return (
    <div className="settings-page">
      <div className="settings-container">
        <h1 className="settings-title">הגדרות</h1>
        <h2 className="settings-welcome">ברוך הבא, {userName}</h2>

        <div className="settings-group">
          <div className="setting">
            <label htmlFor="language">שפה:</label>
            <select id="language" value={language} onChange={handleLanguageChange}>
              <option value="English">English</option>
              <option value="Hebrew">עברית</option>
              <option value="Yiddish">יידיש</option>
            </select>
          </div>

          <div className="setting">
            <label>מצב כהה:</label>
            <button 
              onClick={handleDarkModeToggle}
              className={`toggle-button ${darkMode ? 'active' : ''}`}
            >
              {darkMode ? 'כבוי' : 'פעיל'}
            </button>
          </div>

          <div className="setting">
            <label>התראות:</label>
            <button 
              onClick={handleNotificationsToggle}
              className={`toggle-button ${notifications ? 'active' : ''}`}
            >
              {notifications ? 'פעיל' : 'כבוי'}
            </button>
          </div>
        </div>

        <div className="settings-group">
          <h3 className="settings-subtitle">פעולות חשבון</h3>
          <div className="setting">
            <button onClick={handleLogout} className="action-button logout-button">
              התנתק
            </button>
          </div>
          <div className="setting">
            <button onClick={handleDeleteAccount} className="action-button delete-account-button">
              מחק את החשבון שלי
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}