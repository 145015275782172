import React, { useState } from 'react';
import '../style/ContactPage.css';

export default function ContactPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('idle');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus('idle');

    try {
      // כאן בעתיד תוכל לשלוח את הנתונים לשרת או API
      // לעת עתה, נדמה שליחה מוצלחת
      await new Promise(resolve => setTimeout(resolve, 1000));
      console.log('Form submitted:', { name, email, message });
      setSubmitStatus('success');
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-page-container">
    <div className="contact-page">
      <h1 className="contact-title">צור קשר</h1>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="name" className="form-label">שם:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="form-input"
            placeholder="הכנס את שמך"
          />
        </div>
        <div className="form-group">
          <label htmlFor="email" className="form-label">אימייל:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="form-input"
            placeholder="הכנס את כתובת האימייל שלך"
          />
        </div>
        <div className="form-group">
          <label htmlFor="message" className="form-label">הודעה:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            className="form-textarea"
            placeholder="כתוב את הודעתך כאן"
          ></textarea>
        </div>
        <button type="submit" className="submit-button" disabled={isSubmitting}>
          {isSubmitting ? 'שולח...' : 'שלח הודעה'}
        </button>
      </form>
      {submitStatus === 'success' && <p className="success-message">ההודעה נשלחה בהצלחה!</p>}
      {submitStatus === 'error' && <p className="error-message">אירעה שגיאה בשליחת ההודעה. אנא נסה שנית.</p>}
    </div>
    </div>
  );
}