import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { FiPlus, FiSend, FiImage, FiTrash2, FiChevronUp, FiChevronDown, FiMic, FiStopCircle, FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { FaRobot } from 'react-icons/fa';
import { fetchInstructions, fetchChats, createChat, updateChat as updateChatInFirebase, updateChatTitle as updateChatTitleInFirebase, deleteChat as deleteChatInFirebase, auth, storage, db } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, query, onSnapshot } from 'firebase/firestore';
import '../style/AIPage.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaUserCircle, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { signOut } from 'firebase/auth';
import ReactMarkdown from 'react-markdown';

export default function AIPage() {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [chats, setChats] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [inputText, setInputText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [instructions, setInstructions] = useState('');
  const messagesContainerRef = useRef(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const fileInputRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [predefinedMessages, setPredefinedMessages] = useState([]);
  const [isInputExpanded, setIsInputExpanded] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const [userLocation, setUserLocation] = useState(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setIsSidebarOpen(!mobile);
      
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call this immediately to set the initial state

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const createNewChat = useCallback(async () => {
    if (!user) return;

    const newChat = {
      userId: user.uid,
      messages: [
        {
          sender: 'gpt',
          content: "שלום! איך אוכל לסייע לך היום בנושאים הקשורים לדת או יהדות?",
        }
      ],
      title: "צ'אט חדש",
      createdAt: new Date()
    };

    const newChatId = await createChat(newChat);
    setChats(prevChats => [{ id: newChatId, ...newChat }, ...prevChats]);
    setActiveChat(newChatId);
  }, [user]);

  const loadUserChats = useCallback(async (userId) => {
    setIsLoading(true);
    try {
      const userChats = await fetchChats(userId);
      setChats(userChats);
      if (userChats.length === 0) {
        await createNewChat();
      } else {
        setActiveChat(userChats[0].id);
      }
    } catch (error) {
      console.error("Error loading user chats:", error);
    } finally {
      setIsLoading(false);
    }
  }, [createNewChat]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        loadUserChats(currentUser.uid);
      } else {
        setChats([]);
        setActiveChat(null);
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [loadUserChats]);

  useEffect(() => {
    const loadInstructions = async () => {
      try {
        const fetchedInstructions = await fetchInstructions();
        if (fetchedInstructions) {
          setInstructions(fetchedInstructions);
        }
      } catch (error) {
        console.error("Error loading instructions:", error);
      }
    };

    loadInstructions();
  }, []);

  useEffect(() => {
    const q = query(collection(db, 'predefinedMessages'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messagesArray = querySnapshot.docs.map(doc => ({
        id: doc.id,
        content: doc.data().content
      }));
      setPredefinedMessages(messagesArray);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
    adjustTextareaHeight(e.target);
  };

  const adjustTextareaHeight = (element) => {
    element.style.height = 'auto';
    const newHeight = Math.min(element.scrollHeight, 100);
    element.style.height = `${newHeight}px`;
    setIsInputExpanded(newHeight > 50);
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      const chunks = [];

      mediaRecorderRef.current.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' });
        setAudioBlob(blob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleSendAudio = async () => {
    if (!audioBlob || !activeChat || !auth.currentUser || isWaitingForResponse) return;

    setIsWaitingForResponse(true);
    try {
      const storageRef = ref(storage, `chat_audio/${auth.currentUser.uid}/${Date.now()}_audio.webm`);
      await uploadBytes(storageRef, audioBlob);
      const downloadURL = await getDownloadURL(storageRef);

      const audioMessage = { sender: 'user', content: downloadURL, type: 'audio' };
      await updateChatInFirebase(activeChat, audioMessage);
      updateLocalChat(activeChat, audioMessage);

      const reader = new FileReader();
      reader.readAsDataURL(audioBlob);
      reader.onloadend = async function() {
        const base64Audio = reader.result.split(',')[1];
        const transcription = await transcribeAudio(base64Audio);
        await handleSendRequest(transcription, 'audio');
      }
    } catch (error) {
      console.error('Error sending audio:', error);
      const errorMessage = { sender: 'gpt', content: 'מצטערים, הייתה שגיאה בעיבוד הודעת האודיו שלך. אנא נסה שוב.' };
      await updateChatInFirebase(activeChat, errorMessage);
      updateLocalChat(activeChat, errorMessage);
    } finally {
      setAudioBlob(null);
      setIsWaitingForResponse(false);
    }
  };

  const transcribeAudio = async (base64Audio) => {
    try {
      const response = await axios.post(
        'https://speech.googleapis.com/v1/speech:recognize',
        {
          config: {
            encoding: 'WEBM_OPUS',
            sampleRateHertz: 48000,
            languageCode: 'he-IL',
            model: 'default'
          },
          audio: {
            content: base64Audio
          }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_GOOGLE_CLOUD_API_KEY}`
          }
        }
      );

      if (response.data.results && response.data.results.length > 0) {
        return response.data.results[0].alternatives[0].transcript;
      } else {
        throw new Error('No transcription result');
      }
    } catch (error) {
      console.error('Error transcribing audio:', error);
      throw error;
    }
  };

  const analyzeImage = async (base64Image) => {
    try {
      const response = await axios.post(
        'https://vision.googleapis.com/v1/images:annotate',
        {
          requests: [
            {
              image: {
                content: base64Image
              },
              features: [
                { type: 'LABEL_DETECTION' },
                { type: 'TEXT_DETECTION' },
                { type: 'OBJECT_LOCALIZATION' }
              ]
            }
          ]
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_GOOGLE_CLOUD_API_KEY}`
          }
        }
      );

      const labels = response.data.responses[0].labelAnnotations.map(label => label.description).join(', ');
      const text = response.data.responses[0].textAnnotations ? response.data.responses[0].textAnnotations[0].description : 'No text detected';
      const objects = response.data.responses[0].localizedObjectAnnotations.map(obj => obj.name).join(', ');

      return `Image analysis: Labels: ${labels}. Text: ${text}. Objects: ${objects}.`;
    } catch (error) {
      console.error('Error analyzing image:', error);
      throw error;
    }
  };

  const handleSendRequest = async (content = inputText, type = 'text') => {
    if ((type === 'text' && content.trim() === '') || !activeChat || !auth.currentUser || isWaitingForResponse) return;

    setIsWaitingForResponse(true);
    const userMessage = { sender: 'user', content: type === 'audio' ? `Audio: ${content}` : content, type };
    await updateChatInFirebase(activeChat, userMessage);
    updateLocalChat(activeChat, userMessage);
    setInputText('');
    setIsTyping(true);

    try {
      const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
      if (!apiKey) {
        throw new Error('OpenAI API key is not set');
      }

      const currentChat = chats.find(chat => chat.id === activeChat);
      const chatHistory = currentChat ? currentChat.messages : [];

      let systemContent = instructions;
      if (userLocation) {
        systemContent += ` המיקום הנוכחי של המשתמש הוא: קו רוחב ${userLocation.latitude}, קו אורך ${userLocation.longitude}.`;
      }

      const apiResponse = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o',
          messages: [
            { role: 'system', content: systemContent },
            ...chatHistory.map(msg => ({
              role: msg.sender === 'user' ? 'user' : 'assistant',
              content: msg.content
            })),
            { role: 'user', content }
          ],
          max_tokens: 2000,
          temperature: 0.7,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
          },
        }
      );

      const botMessage = apiResponse.data.choices && apiResponse.data.choices.length > 0
        ? { sender: 'gpt', content: apiResponse.data.choices[0].message.content.trim() }
        : { sender: 'gpt', content: 'לא התקבלה תשובה תקינה מה-API.' };

      await updateChatInFirebase(activeChat, botMessage);
      updateLocalChat(activeChat, botMessage);
      await updateChatTitleInFirebase(activeChat, type === 'text' ? content : 'הודעת אודיו');
      updateLocalChatTitle(activeChat, type === 'text' ? content : 'הודעת אודיו');
    } catch (error) {
      console.error('Error fetching data from OpenAI:', error);
      const errorMessage = { sender: 'gpt', content: 'מצטערים, משהו השתבש. אנא נסה שוב מאוחר יותר.' };
      await updateChatInFirebase(activeChat, errorMessage);
      updateLocalChat(activeChat, errorMessage);
    } finally {
      setIsTyping(false);
      setIsWaitingForResponse(false);
    }
  };

  const updateLocalChat = (chatId, message) => {
    setChats(prevChats => 
      prevChats.map(chat => 
        chat.id === chatId 
          ? { ...chat, messages: [...(chat.messages || []), message] }
          : chat
      )
    );
  };

  const updateLocalChatTitle = (chatId, userInput) => {
    setChats(prevChats =>
      prevChats.map(chat =>
        chat.id === chatId && chat.title === "צ'אט חדש"
          ? { ...chat, title: userInput.slice(0, 30) }
          : chat
      )
    );
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chats, isTyping]);

  const handlePredefinedRequest = (text) => {
    setInputText(text);
    handleSendRequest(text);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendRequest();
    }
  };

  const handleDeleteChat = async (chatId) => {
    try {
      await deleteChatInFirebase(chatId);
      setChats(prevChats => prevChats.filter(chat => chat.id !== chatId));
      if (activeChat === chatId) {
        setActiveChat(chats[0]?.id || null);
      }
    } catch (error) {
      console.error("Error deleting chat:", error);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const storageRef = ref(storage, `chat_images/${user.uid}/${Date.now()}_${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      const imageMessage = { sender: 'user', content: downloadURL, type: 'image' };
      await updateChatInFirebase(activeChat, imageMessage);
      updateLocalChat(activeChat, imageMessage);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async function() {
        const base64Image = reader.result.split(',')[1];
        const imageAnalysis = await analyzeImage(base64Image);
        await handleSendRequest(imageAnalysis, 'image');
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const toggleInputExpansion = () => {
    setIsInputExpanded(!isInputExpanded);
  };

  return (
    <div className={`ai-page ${isSidebarOpen ? 'sidebar-open' : ''}`} dir="rtl">
      {isLoading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <>
          <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
            <div className="sidebar-content">
              <button className="new-chat-button" onClick={createNewChat}>
                צ'אט חדש <FiPlus className="icon" />
              </button>
              <div className="chat-list">
                {chats.map(chat => (
                  <div
                    key={chat.id}
                    className={`chat-item ${activeChat === chat.id ? 'active' : ''}`}
                  >
                    <button
                      className="delete-chat-button"
                      onClick={() => handleDeleteChat(chat.id)}
                    >
                      <FiTrash2 />
                    </button>
                    <span className="chat-title" onClick={() => setActiveChat(chat.id)}>
                      {chat.title}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="main-content">
            <div className="chat-area" ref={messagesContainerRef}>
              {chats.find(chat => chat.id === activeChat)?.messages?.map((message, index) => (
                <div
                  key={index}
                  className={`message ${message.sender === 'user' ? 'user-message' : 'gpt-message'}`}
                >
                  {message.sender === 'gpt' && (
                    <div className="ai-icon">
                      <FaRobot />
                    </div>
                  )}
                  <div className="message-content">
                    {message.type === 'image' ? (
                      <img src={message.content} alt="תמונה שהועלתה" className="uploaded-image" />
                    ) : message.type === 'audio' ? (
                      <audio controls src={message.content} className="audio-message" />
                    ) : (
                      <ReactMarkdown>{message.content}</ReactMarkdown>
                    )}
                  </div>
                </div>
              ))}
              {isTyping && (
                <div className="message gpt-message">
                  <div className="ai-icon">
                    <FaRobot />
                  </div>
                  <div className="message-content">
                    <div className="typing-indicator">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={`input-area ${isInputExpanded ? 'expanded' : ''}`}>
              <div className="input-container">
                <textarea
                  className="input-text"
                  value={inputText}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  placeholder="הקלד את ההודעה שלך כאן..."
                  rows={1}
                  disabled={isWaitingForResponse || isRecording}
                />
                <button className="send-button" onClick={() => handleSendRequest()} disabled={isWaitingForResponse || isRecording}>
                  <FiSend />
                </button>
                <button className="upload-button" onClick={() => fileInputRef.current.click()} disabled={isWaitingForResponse || isRecording}>
                  <FiImage />
                </button>
                <button 
                  className={`record-button ${isRecording ? 'recording' : ''}`} 
                  onClick={isRecording ? stopRecording : startRecording}
                  disabled={isWaitingForResponse}
                >
                  {isRecording ? <FiStopCircle /> : <FiMic />}
                </button>
                {audioBlob && (
                  <button className="send-audio-button" onClick={handleSendAudio} disabled={isWaitingForResponse}>
                    שלח הקלטה
                  </button>
                )}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                  accept="image/*"
                  disabled={isWaitingForResponse || isRecording}
                />
              </div>
              <div className="predefined-requests-wrapper">
                <button className="toggle-predefined" onClick={toggleInputExpansion}>
                  {isInputExpanded ? <FiChevronDown /> : <FiChevronUp />}
                </button>
                <div className="predefined-requests">
                  {predefinedMessages.map((message) => (
                    <button
                      key={message.id}
                      className="predefined-request-button"
                      onClick={() => handlePredefinedRequest(message.content)}
                      disabled={isWaitingForResponse || isRecording}
                    >
                      {message.content}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <button className="mobile-toggle-sidebar" onClick={toggleSidebar}>
            {isSidebarOpen ? <FiChevronRight /> : <FiChevronLeft />}
          </button>
        </>
      )}
    </div>
  );
}